// B&W 
$white: #fff;
$alabaster: #fbfbfb;
$cultured: #f0f0f0ff;
$mercury: #e3e3e3;
$silver: #c7c7c7;
$silver-chalice: #b2b2b2ff;
$dove-gray: #6b6b6b;
$jet: #2f2f2fff;
$black: #000;

// accent-reds
$dark-sienna: #341011ff;
$rosewood: #5c0107ff;
$ruby-red: #95141bff;
$fire-engine-red: #cc1e2aff;
$fiery-rose: #ff5c67ff;

// candycode accends
$brunswick-green: #355145ff;
$mantis: #7ad777ff;
$arylide-yellow: #dec95eff;
$orange-soda: #ea5635ff;
$chinese-red: #9e3118ff;

// bright accent
$dark-magenta: #960b8dff;
$blue-violet-color-wheel: #530866ff;
$cinnabar: #e84638ff;
$english-vermillion: #d44648ff;
$pink-pantone: #db5a9dff;

// accent-peach
$pale-silver: #d4c5bcff;
$english-red: #a34553ff;
$cinnamon-satin: #d44e70ff;
$raw-sienna: #d17c43ff;
$marigold: #e8a73fff;

// red-orange-green theme
$eerie-black: #222323ff;
$russian-green: #5e9071ff;
$auburn: #9f3131ff;
$copper-red: #d66b4dff;
$tuscany: #bb9d9aff;

// deep-blue magentish purple theme
$midnight-blue: #1b1974ff;
$cosmic-cobalt: #2a2a8aff;
$blue-pigment: #3b38a8ff;
$amaranth-purple: #a5334bff;
$antique-ruby: #7e1a2fff;
$cyanish: #7dd0ad;
$space-cadet: #0e0e0e; //#1b1b35ff;
$raisin-black: #292929; //#242435ff;

$middle-yellow: #ffea00ff;
$laser-lemon: rgb(255, 246, 116);

