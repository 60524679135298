.login_box {
    // background-color: $alabaster;
    // border: 3px solid color(bg, 2i);
    width: 20vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
 
.inner_box {
    display: flex;
    flex-direction: column;
    // gap: 0px;
    margin: 20px 20px;
    // justify-content: center;
    // align-items: center;
}

.button_01 {
    @include font-weight(blackbold);
    background-color: color(bg, 2);
    align-self: center;
    // min-width: 30%;
    border: 1px solid color(bg, 2i);
    border-radius: 0px;
    margin: 1px 1px;
}

.button_01:hover {
    border: 2px solid color(bg, 2i);
    margin: 0px 0px;

}

input[type=password] {
    background-color: color(bg, 2);
    text-align: center;
    // border: none;
    border: 1px solid color(bg, 2i);

    // border-bottom: 1px solid black;
    outline: none;
    // border: none;
}
