@import url("https://use.typekit.net/ulm4doz.css");

// --------------------------------------------
// Colors -------------------------------------
// Usage example: color(primary, main)
// --------------------------------------------
$color: (
	typography: (
		1: #ffffff,
		2: #000000,
		1i: #e6e6e6,
		2i: #3b3b3b
	),
	bg: (
		1: #FFFFFF,
		2: #e4e4e4,
		3: #cecece,
		1i: #0B0D19,
		2i: #1E233D
	),
	primary: (
		1: #4234F8,
		2: #7065FA,
		3: #1908F1,
		4: #E2E0FE
 	),
	secondary: (
		1: #FF6C50,
		2: #FF816A,
		3: #FF411D,
		4: #FFD8D1
	)
);

// --------------------------------------------
// Typography ---------------------------------
// --------------------------------------------
// $font__family: (
// 	text: '"halyard-text", sans-serif', // font-family(base)
// 	heading: '"forma-djr-display", sans-serif', // font-family(heading)
// 	code: 'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace', // font-family(code)
// 	pre: 'Consolas, monaco, monospace' // font-family(pre)
// );

$font__family: (
	text: '"neue-haas-grotesk", sans-serif', // font-family(base)
	text-serif: '"garamond-premier-pro", serif',
	heading: '"neue-haas-grotesk-display", sans-serif', // font-family(heading)
	heading-serif: '"garamond-premier-pro-display, serif',
	subhead-serif: '"garamond-premier-pro-subhead", serif',
	code: 'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace', // font-family(code)
	pre: 'Consolas, monaco, monospace' // font-family(pre)
);


// html font-size is 10px for the ease of multiplication
$font__sizes: (
	alpha:   ( 5rem, 1.1, 0 ),    // font-size, line-height, kerning (use '0' if don't want to output any kerning)
	beta:    ( 3.2rem, 1.1, 0 ),
	gamma:   ( 3rem, 1.1, 0 ),
	delta:   ( 2rem, 1.1, 0 ),
	epsilon: 	( 1.9rem, 1.1, 0 ),
	zeta:    ( 1.8rem, 1.1, 0 ),
	eta:     ( 1.6rem, 1.1, 0 ),
	theta:   ( 1.4rem, 1.1, 0 )
);

$font__scale: (
	desktop: (                             // i.e. $breakpoint__m + $breakpoint__l (600 - 1024)
		1: map-get($font__sizes, alpha),   // H1
		2: map-get($font__sizes, beta),    // H2
		3: map-get($font__sizes, gamma),   // H3
		4: map-get($font__sizes, delta),   // H4
		5: map-get($font__sizes, epsilon), // Body, H5, H6
		6: map-get($font__sizes, zeta),    // Text small
		7: map-get($font__sizes, eta),     // Text smaller
		8: map-get($font__sizes, theta)    // Footer area
	),
	mobile: (                              // i.e. $breakpoint__xs + $breakpoint__s (up to 600)
		1: map-get($font__sizes, beta),    // H1
		2: map-get($font__sizes, gamma),   // H2
		3: map-get($font__sizes, delta),   // H3
		4: map-get($font__sizes, delta),   // H4
		5: map-get($font__sizes, epsilon), // Body, H5, H6
		6: map-get($font__sizes, zeta),    // Text small
		7: map-get($font__sizes, eta),     // Text smaller
		8: map-get($font__sizes, theta)    // Footer area
	)
);


$font__weight: (
	light: 400,
	regular: 500, 	// font__weight(regular)
	medium: 600,	// font__weight(medium)
	bold: 700,	// font__weight(semibold)
	extrabold: 800,		// font__weight(bold)
	blackbold: 900,
);

// --------------------------------------------
// Structure ----------------------------------
// --------------------------------------------
$content__padding: (
	mobile: 16px,
	desktop:  24px
);
$container__width: 1080px;
$container__width-sm: 800px;
