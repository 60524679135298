.site-header {
    padding: 24px 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

.site-header-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-links {
    display: inline-flex;

    li {
        display: inline-flex;
    }

}
