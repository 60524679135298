

.feature-extended {
    padding: 24px 0;
}

.feature-extended-body {
    text-align: center;
}

@include media( '>medium' ) {
    .features-extended {
		.container {
			max-width: 864px + ( get-content-padding(desktop) * 2 );
		}
    }


    .feature-extended {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: flex-end;
        padding: 64px 0;

		.feature-extended-image {
			width: 440px;
			margin-right: 96px;
			margin-bottom: 0;

			img,
			svg {
				width: auto;

				&.device-mockup {
					max-width: 296px;
				}
			}
		}

		&:nth-child(even) {
			justify-content: flex-start;

			.feature-extended-image {
				order: 1;
				margin-left: 96px;
				margin-right: 0;
			}
		}
    }

	.feature-extended-body {
		/* Align vertically */
		display: flex;
		flex-direction: column;
		justify-content: center;
		/* Align vertically, end */
		flex-shrink: 0;
		width: 360px;
		text-align: left;
	}
}

@include media( '>large' ) {
	.features-extended {
		.container {
		}
	}

	.feature-extended {
		.feature-extended-image {
			margin-right: 64px;
		}
		&:nth-child(even) {
			.feature-extended-image {
				margin-left: 64px;
			}
		}
	}

	.feature-extended-body {
	}
}
