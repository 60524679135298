body {
    background-color: color(bg, 2);
    display: flex;
}

.body-wrap {
    background: color(bg, 2);
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 90vw;
    margin: 0 auto;
}

a {
	color: inherit;
	text-decoration: none;
	border-bottom: 2px solid color(typography, 2);
	// border-bottom: 1px solid color(typography, 1);
	&.image {
	  display: block;
	  text-align: center;
	  border: 0;
	  border-radius: 5px;
	  margin: 30px 0;
	  border-bottom:0px;
	}
  }
  
a:hover {
	border-bottom: 0px;
  }

.intro-container {
	background-color: color(bg, 2);
}

.intro-content {
	margin-top: 5vh;
	margin-left: 10px;
	width: 60vw;
    flex-shrink: 0;
}

.featured-item {
    padding: 5vh 0 2vh 0;
    border-bottom: 2px solid black;
}

.section-container {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content:flex-start;
    font-weight: 400;
}

.prim-section-item {
    flex: 2 1 60%;
    h1, h2, .h1, .h2 {
        font-weight: 800;
    }
}
.prim-section-item:nth-of-type(2) {
    // background: red;
    flex: 1 1 350px;
  }

.secondary {
    max-width: 80vw;
}


.media-container {
    margin: 2px;
    // background-color: cyan;
    a {
        border-bottom: 0px;
    }
}

.media-container > video {
    display: block;
}

.media-container:hover {
    opacity: 0.8;
    transition: .5s ease;

  }

.text-container {
    // background-color: green;
    // margin: 10px;
    padding: 10px;
}

.section-video {
    // padding: 3px;
    // background-color: yellow;
    width: 100%;
}

.sec-section-item {
    // margin: 2px;
    // padding: 3px;
    // background-color: red;
    flex: 1 300px;
    display: flex;
}

.featured-item:nth-child(odd) {
    padding-top: 5vh;
    // background:rgb(15, 131, 131);
    .prim-section-item:nth-of-type(2) {
        // padding: 10px;
        
        // background: blue;
    }
    .section-container {
        justify-content: flex-end;
        align-items:flex-end;
        flex-flow: row-reverse;
        flex-wrap: wrap-reverse;
        text-align: right;
        // background: yellow;
        margin-left: auto;
    }
}



.footer-container {
    margin: 1em 1em;
    text-align: left;
}

.top-bar {
    background-color: color(bg, 2);
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    text-align: right;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1;

}

.centered {
    text-align: center;
}



@media all and (max-width: 900px) {
    .section-container {
      flex-direction: column;
    }
    .secondary {
        max-width: 100vw;
    }
    .prim-section-item:nth-of-type(2) {
        flex: 1;
      }
    .sec-section-item {
        flex: 1;
    }
    .featured-item:nth-child(odd) {
        .prim-section-item:nth-of-type(2) {
        // background: pink;
        }
        .section-container {
        // align-items:flex-end;
        justify-content:flex-start;
        flex-flow: column-reverse;
        flex-wrap: wrap-reverse;
        text-align: right;

        // background: green;
        }
    }
  }

main {
    flex: 1 0 auto;
}

.section-inner {
    position: relative; /* To always display inner elements above pseudo decorative stuff */
    padding-top: 48px;
    padding-bottom: 48px;
}

@include media( '>medium' ) {

    .section-inner {
        padding-top: 88px;
        padding-bottom: 88px;
    }
}



