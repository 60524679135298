html.sr .load-hidden {
    visibility: hidden;
}

.has-animations {
	.reveal {
		opacity: 0;
	}

	&.is-loaded {
		.reveal {
			opacity: 1;
		}
	}
}