@import "_colours";

$body-bg-color: $alabaster;
$body-text-color: $jet;

$main-content-bg-color: $white;
$main-content-text-color: $jet;

$a-text-color: $jet;
$a-bg-color: transparent;
$a-border-color: $dove-gray;
$a-hover-text-color: $silver;
$a-hover-bg-color: transparent;
$a-hover-border-color: transparent;


$logo-a-bg-color: $white;
$logo-a-border-color: transparent;
$logo-a-color: $black;
$logo-a-hover-text-color: $white;
$logo-a-hover-bg-color: $black;
$logo-a-hover-border-color: transparent;

$menu-a-background-color: $white;
$menu-a-text-color: $black;
$menu-a-hover-bg-color: $black;
$menu-a-hover-text-color: $white;


$highlight-color: $laser-lemon;
$blockquote-bg-color: $cultured;
$blockquote-border-color: $jet;
$blockquote-text-color: $jet;

$heading-text-color: $jet;
$heading1-text-color: $jet;
$heading2-text-color: $cyanish;
$heading3-text-color: $jet;


$divider-color: $mercury;
$hr-color: $silver;

$footnote-ref-text-color: $jet;
$footnote-ref-bg-color: $mercury;

$footnotes-text-color: $dove-gray;

$footer-text-color: $dove-gray;


$code-default-bg-color: $cultured;
$code-text-color: $jet;
$code-border-color: $silver;
$code-inline-border-color: $mercury;

$table-bg-color: $cultured;
$table-border-color: $cultured;

$time-text-color: $dove-gray;


$section-a-text-color: $black;
$section-span-text-color: $dove-gray;
$section-media-hover-border-color: $black;
