

body {
	@include font-family(text);
	@include font-size(5, mobile, true, true, true);
	@if ( get-font-size(5, desktop) != get-font-size(5, mobile) ) {
		@include media( '>medium' ) {
			@include font-size(5, desktop, true, true, true);
		}
	}
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@include font-family(heading);
	@include font-weight(regular)
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	clear: both;
	color: color(typography, 0);
	@if ( get-font-family(heading) != get-font-family(base) ) {
		@include font-family(heading);
	}
	@include font-weight(medium);
}

h1,
.h1 {
	@include font-weight(blackbold);
	@include font-size(1, mobile, true, true, true);
	@if ( get-font-size(1, desktop) != get-font-size(1, mobile) ) {
		@include media( '>medium' ) {
			@include font-size(1, desktop, true, true, true);
		}
	}
}

h2,
.h2 {
	@include font-weight(bold);
	@include font-size(2, mobile, true, true, true);
	@if ( get-font-size(2, desktop) != get-font-size(2, mobile) ) {
		@include media( '>medium' ) {
			@include font-size(2, desktop, true, true, true);
		}
	}
}

h3,
.h3,
blockquote {
	@include font-size(3, mobile, true, true, true);
	@if ( get-font-size(3, desktop) != get-font-size(3, mobile) ) {
		@include media( '>medium' ) {
			@include font-size(3, desktop, true, true, true);
		}
	}
}

h4,
.h4 {
	@include font-size(5, mobile, true, true, true);
	@if ( get-font-size(5, desktop) != get-font-size(5, mobile) ) {
		@include media( '>medium' ) {
			@include font-size(5, desktop, true, true, true);
		}
	}
}

h5,
.h5,
h6,
.h6 {
	@include font-size(5, mobile, true, true, true);
	@if ( get-font-size(5, desktop) != get-font-size(5, mobile) ) {
		@include media( '>medium' ) {
			@include font-size(5, desktop, true, true, true);
		}
	}
}

h1, h2,
.h1, .h2 {
	margin-top: 10px;
	margin-bottom: 10px;
}

h3,
.h3 {
	margin-top: 10px;
	margin-bottom: 10px;
}

h4, h5, h6,
.h4, .h5, .h6 {
	margin-top: 5px;
	margin-bottom: 2px;
}

.intro-content h2, h3 {
	font-family: neue-haas-grotesk-display, sans-serif;
}

.intro-content h4, h5 {
	font-family: neue-haas-grotesk-text, sans-serif;
}

.intro-content h2 {
	font-weight: 500;
	font-size: 1.5em;
	// font-style: italic;
}

.intro-content h3 {
	font-size: 1.1em;
	font-style: italic;
}

.intro-content h4 {
	// text-transform: lowercase;
	// font-variant: small-caps;
	// font-size: 1em;
	// font-weight: 600;
	text-transform: uppercase;
	font-size: 0.75em;
	font-weight: 600;
	line-height: 1.6em;
	// padding-top: 0em;
	// padding-bottom: 0em;
	// margin-top: 0em;
	// margin-bottom: 0em;
}

.featured-item p {
	font-family: neue-haas-grotesk-text, serif;
	// font-style: italic;
	font-size: 0.9em;
	line-height: 1.3em;
	font-weight: 400;
}
.text-container h3 {
	// text-transform: uppercase;
	font-variant: small-caps;
	font-weight: 600;
	font-size: 1.7em;
}
.scope {
	margin-top: 0.3em;
	padding-top: 0.3em;
  }

.footer-container h2 {
	text-transform: uppercase;
	font-size: 1em;
	font-weight: 600;
}

.copy {
	font-size: 0.7em;
}